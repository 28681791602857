function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
export var scrollToNode = function scrollToNode(container, scrollDOM) {
  if (!container) {
    return;
  }
  container.scrollTop = (scrollDOM === null || scrollDOM === void 0 ? void 0 : scrollDOM.offsetTop) || 0;
};

// create link to prefetch medail url
export var prefetchMedialUrl = function prefetchMedialUrl(url, type) {
  var link = document.createElement('link');
  link.rel = 'prefetch';
  link.href = url;
  link.as = type;
  link.crossOrigin = 'crossorigin';
  document.head.appendChild(link);
  setTimeout(function () {
    document.head.removeChild(link);
  }, 1000);
};
export var breadthFirstSearch = function breadthFirstSearch(rootElement, selector) {
  // 创建一个队列来存储待处理的元素
  var queue = [rootElement];
  // 创建一个集合来存储已经访问过的元素
  var visited = new Set();
  while (queue.length > 0) {
    // 从队列中取出第一个元素
    var currentElement = queue.shift();
    // 检查当前元素是否已经访问过
    if (visited.has(currentElement)) {
      continue;
    }
    // 将当前元素标记为已访问
    visited.add(currentElement);

    // 检查当前元素是否匹配选择器
    if (currentElement.matches(selector)) {
      // 如果匹配，则返回当前元素
      return currentElement;
    }

    // 将当前元素的所有子元素添加到队列中
    var _iterator = _createForOfIteratorHelper(currentElement.children),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var childElement = _step.value;
        queue.push(childElement);
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }

  // 如果没有找到匹配的元素，则返回 null
  return null;
};