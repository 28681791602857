/**
 * Calculate module width and height in ratio by data-module-id
 */
export var getModuleSizeByModuleId = function getModuleSizeByModuleId(moduleId) {
  var module = document.querySelector("[data-module-id=\"".concat(moduleId, "\"]"));
  if (!module) {
    return {};
  }
  var data = module.getBoundingClientRect();
  return {
    width: data.width,
    height: data.height,
    ratio: Number.parseFloat((data.width / data.height).toFixed(2))
  };
};