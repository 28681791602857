export function idleCallback(cb) {
  if ('requestIdleCallback' in window) {
    // 如果浏览器支持requestIdleCallback，则使用它
    requestIdleCallback(cb);
  } else {
    // 如果不支持，则使用 messagechannel 作为备选方案
    var message = new MessageChannel();
    var port1 = message.port1;
    var port2 = message.port2;
    var timestamp;
    port2.onmessage = function (e) {
      // 判断是否有空间时间，有才执行
      if (timestamp - performance.now() > 0 && cb) {
        cb();
      }
    };
    requestAnimationFrame(function (rafTime) {
      // 结束时间点 = 开始时间点 + 一帧用时16.667ms
      timestamp = rafTime + 16.7;

      // 发送个宏任务
      port1.postMessage(null);
    });
  }
}